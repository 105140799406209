<!-- Nav Start-->
<header class="agency nav-abs custom-scroll">
    <div class="container">
        <div class="row">
                <nav>
                    <a href="#" class="logo-light d-inline-block m-r-auto"><img src="assets/images/brasao.png" alt=""
                            class="img-fluid"></a>
                    <app-menu></app-menu>
                </nav>
           
        </div>
    </div>
</header>
    <!-- <div class="container">
        <div class="cards">
            <div class="card">
                <div class="info-item">
                    <div class="icon ouvidoria"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                            viewBox="0 0 64 64" fill="none" class="img-fluid d-block top-svg svg replaced-svg">
                            <g clip-path="url(#clip0_3_71)">
                                <path
                                    d="M46.1439 49.0701C51.209 48.392 55.8408 45.8545 59.1387 41.9509C62.4367 38.0473 64.165 33.0567 63.9876 27.9496C63.8102 22.8425 61.7398 17.9838 58.179 14.3185C54.6181 10.6531 49.8214 8.44308 44.7216 8.11806C39.6217 7.79305 34.5832 9.37631 30.5859 12.56C26.5886 15.7436 23.9181 20.3001 23.0939 25.3434C22.2696 30.3867 23.3504 35.5563 26.1261 39.847C28.9018 44.1376 33.1741 47.2426 38.112 48.5582L37.6604 56.5901L46.1439 49.0701Z"
                                    fill="url(#paint0_linear_3_71)"></path>
                                <path
                                    d="M17.8855 49.04C13.9602 48.5164 10.2689 46.8731 7.25303 44.3066C4.2372 41.7402 2.02459 38.3592 0.879878 34.5682C-0.264836 30.7773 -0.293182 26.7368 0.798231 22.9301C1.88964 19.1234 4.0546 15.7118 7.03412 13.1032C10.0136 10.4947 13.6816 8.79978 17.5991 8.22119C21.5167 7.6426 25.5179 8.20485 29.1243 9.84069C32.7307 11.4765 35.7895 14.1167 37.9347 17.4453C40.08 20.774 41.2208 24.6501 41.2209 28.6102C41.1979 33.1468 39.6879 37.5506 36.9224 41.1469C34.1569 44.7432 30.2884 47.3334 25.9098 48.5206L26.3615 56.5525L17.8855 49.04Z"
                                    fill="#021E43"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M32.09 10.9882C35.0038 12.952 37.3928 15.5989 39.0487 18.6981C40.7045 21.7972 41.5769 25.2545 41.5898 28.7682C41.5898 35.8667 37.4873 42.5437 31.9846 46.2472C26.3615 42.5663 22.8311 35.8291 22.8311 28.6478C22.8367 25.1759 23.6815 21.7568 25.2937 18.6819C26.9058 15.607 29.2375 12.9674 32.09 10.9882"
                                    fill="#021E43"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M32.09 10.9882C35.0038 12.952 37.3928 15.5989 39.0487 18.6981C40.7045 21.7972 41.5769 25.2545 41.5898 28.7682C41.5898 35.8667 37.4873 42.5437 31.9846 46.2472C26.3615 42.5663 22.8311 35.8291 22.8311 28.6478C22.8367 25.1759 23.6815 21.7568 25.2937 18.6819C26.9058 15.607 29.2375 12.9674 32.09 10.9882"
                                    fill="black" fill-opacity="0.2"></path>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_3_71" x1="43.4121" y1="8.07635" x2="43.4121"
                                    y2="56.5901" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#B71D22"></stop>
                                    <stop offset="1" stop-color="#92171B"></stop>
                                </linearGradient>
                                <clipPath id="clip0_3_71">
                                    <rect width="64" height="64" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg></div>
                    <p>Ouvidoria</p>
                </div>
            </div>
            <div class="card">
                <div class="info-item">
                    <div class="icon transparencia"></div>
                    <p>Portal <br> Transparência</p>
                </div>
            </div>
            <div class="card">
                <div class="info-item">
                    <div class="icon sic"></div>
                    <p>Serviço de <br> Informação ao <br> Cidadão</p>
                </div>
            </div>
            <div class="w100 search-bar">
                <input type="text" placeholder="Pesquisar..." class="search-input">
                <button class="search-button">Buscar</button>
            </div>
        </div>

    </div> -->






<!-- Nav end-->