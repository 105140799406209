import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SharedModule } from "../shared/shared.module";
import { AgencyBlogComponent } from "./agency/agency-blog/agency-blog.component";
import { AgencyContentComponent } from "./agency/agency-content/agency-content.component";
import { AgencyCopyrightComponent } from "./agency/agency-copyright/agency-copyright.component";
import { AgencyCounterComponent } from "./agency/agency-counter/agency-counter.component";
import { AgencyFooterComponent } from "./agency/agency-footer/agency-footer.component";
import { AgencyHeaderComponent } from "./agency/agency-header/agency-header.component";
import { AgencyNavComponent } from "./agency/agency-nav/agency-nav.component";
import { AgencyPricingComponent } from "./agency/agency-pricing/agency-pricing.component";
import { AgencyServicesComponent } from "./agency/agency-services/agency-services.component";
import { AgencySpeakerComponent } from "./agency/agency-speaker/agency-speaker.component";
import { AgencyTestimonialComponent } from "./agency/agency-testimonial/agency-testimonial.component";
import { AgencyVideoComponent } from "./agency/agency-video/agency-video.component";
// Agency Layout
import { AgencyComponent } from "./agency/agency.component";
import { LayoutsRoutingModule } from "./layouts-routing.module";
import { CarouselConteudoComponent } from "./agency/carousel-conteudo/carousel-conteudo.component";
import { VereadoresPreviewComponent } from "./agency/vereadores-preview/vereadores-preview.component";
import { VereadoresComponent } from "./agency/vereadores-preview/vereadores/vereadores.component";
import { BreadcrumbComponent } from "../shared/breadcrumb/breadcrumb.component"
import { NewsComponent } from "./agency/news/news.component";
import { NewsPreviewComponent } from "./agency/news/news-preview/news-preview.component";
import { GalleryComponent } from "./agency/gallery/gallery.component";


@NgModule({
  declarations: [
    AgencyComponent,
    AgencyNavComponent,
    AgencyHeaderComponent,
    AgencyContentComponent,
    AgencyServicesComponent,
    AgencyVideoComponent,
    AgencyCounterComponent,
    AgencyPricingComponent,
    AgencySpeakerComponent,
    AgencyTestimonialComponent,
    AgencyBlogComponent,
    AgencyFooterComponent,
    AgencyCopyrightComponent,
    CarouselConteudoComponent,
    VereadoresPreviewComponent,
    VereadoresComponent,
    BreadcrumbComponent,
    NewsComponent,
    NewsPreviewComponent,
    GalleryComponent

  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class LayoutsModule {}
