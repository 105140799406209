<div class="container">
    <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
            <a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
            <span *ngIf="last">{{ breadcrumb.label }}</span>
          </li>
        </ul>
      </nav>
</div>

  