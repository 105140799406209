<div class="abs">
    <div class="container">
        <div class="desktop-body-3">
            <ul *ngIf="newsSelected == null" class="breadcrumb" style="background: white;">
                <li><a href="#">Home</a></li>
                <li>/</li>
                <li><strong>Notícias</strong></li>
            </ul>
            <ul *ngIf="newsSelected !== null" class="breadcrumb" style="background: white;">
                <li><a href="#">Home</a></li>
                <li>/</li>
                <li>Notícias</li>
                <li>/</li>
                <li><strong>{{newsSelected.titulo}}</strong></li>
            </ul>
        </div>
    </div>

</div>

<section>
    <div class="container">
        <h1 *ngIf="newsSelected == null" class="desktop-headeline-2 mt-44">Notícias</h1>
        <div *ngIf="newsSelected == null" class="row mt-44">
            <div class="col-lg-12 col-md-12 col-sm-12 pd">
                <div *ngFor="let item of news" class="news-card" (click)="selectNews(item)">
                    <div class="image-content">
                        <img [src]="item.image" alt="Imagem da area" />
                    </div>
                    <div class="text-content">
                        <h3>{{item.titulo}}</h3>
                        <p>{{item.detail}}
                        </p>
                        <a href="#">Acessar noticia →</a>
                    </div>

                </div>
            </div>

        </div>
        <div *ngIf="newsSelected" class="mt-16">

            <div class="conteudo" id="conteudo">
                <h1 class="desktop-headeline-2">
                    {{newsSelected.titulo}} </h1>
                <h2 class="mt-8">{{newsSelected.detail}}</h2>
                <div class="div-author mt-16">
                    <div class="author">
                        <p class="flex g-13">
                            <strong>
                                Por:
                            </strong>
                            <em style="margin: 0; font-style: normal;">
                                ADMIN<br>
                                DA REDAÇÃO </em>
                        </p>
                        <span>
                            22 de fevereiro de 2025 - 22:31 </span>
                    </div>
                    <div class="social-media flex-end flex g-8">
                        <a href="#" target="_blank">
                            <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-linkedin.svg"
                                alt="Compartilhar Linkedin">
                        </a>
                        <a href="#" target="_blank">
                            <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-facebook.svg"
                                alt="Compartilhar Facebook">
                        </a>
                        <a href="#" target="_blank">
                            <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-twitter.svg"
                                alt="Compartilhar Twitter">
                        </a>
                        <a href="#" target="_blank">
                            <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-email.svg"
                                alt="Compartilhar E-mail">
                        </a>
                        <a href="#">
                            <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-favorito.svg"
                                alt="Adicionar ao favorito">
                        </a>
                        <a href="#" class="comments" aria-label="Comentários" title="Comentários">
                            <span>
                                0 </span>
                            <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-comments.svg"
                                alt="Comentários">
                        </a>
                    </div>
                </div>
            </div>



            <div class="flex flex-post g-32">
                <div class="w100">
                    <div class="content-post entry-content cf">
                        <button class="button-primary" onclick="lerTexto()" type="button" value="Play" id="play">Ouvir
                            esta notícia</button>
                        <button class="button-secondary" onclick="pausarLeitura()" type="button" value="Pausar"
                            id="pause" style="display: none;margin-top: 0px">Pausar leitura</button>
                        <div class="leitura" id="leitura">
                            <div class="media-credit-container alignnone" style="max-width: 810px">
                                <a href="#" data-slb-active="1" data-slb-asset="2094210358" data-slb-internal="0"
                                    data-slb-group="352314"><img fetchpriority="high" decoding="async"
                                        class="wp-image-352315" [src]="newsSelected.image" alt="" width="800"
                                        height="534" sizes="(max-width: 800px) 100vw, 800px"></a><span
                                    class="media-credit">ADMIN | REDAÇÃO</span> </div>

                            <p>{{newsSelected.descricao}}</p>

                        </div>
                        <div class="col-12">
                            <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/2lMUHlHX1b0?si=wuF8Qt7xWuDKRnoc"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>


                        <button class="button-primary" type="button" id="comentar">Deixar um comentário</button>
                    </div>

                </div>
                <div class="sidebar">
                    <div class="barra-lateral-noticias">
                        <h3 class="titulo-news-lateral">Últimas notícias</h3>


                    </div>
                    <div class="barra-lateral-noticias mt-16">
                        <h2 class="titulo-news-lateral">Notícias mais lidas</h2>

                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<div class="w100 mt-32" style="background-color: #223988;">
    <div class="container" style="padding: 15px;">
        <div class="row w100 atendimento">
            <div class="col-lg-2 col-md-12 col-sm-12">
                <h3 class="text-white">Atendimento:</h3>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 text-center">
                <h6 class="text-white">
                    (65) 3301-1150
                </h6>
                <h6 class="text-white">Das 13:00hs ás 18:00hs
                </h6>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 text-center">
                <h6 class="text-white">Câmara Municipal de Chapada dos Guimarães - MT</h6>
                <h6 class="text-white">Av. Fernando Corrêa, Centro, n°. 763, CEP: 78.195-000, </h6>
                <a class="text-white" href="mailto:administrativo&#64;camarachapadadosguimaraes.mt.gov.br">
                    <span>administrativo&#64;camarachapadadosguimaraes.mt.gov.br</span>
                </a>
            </div>

        </div>
    </div>

</div>