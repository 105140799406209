import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../../service/nav.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[] = [];
  public openSide: boolean = false;
  public activeItem: string = '';
  public activeChildItem: string = '';

  constructor(private navService: NavService) {}

  ngOnInit(): void {
    this.navService.items.subscribe((menuItems: Menu[]) => {
      this.menuItems = menuItems;
    });
  }

  toggleSidebar(): void {
    this.openSide = !this.openSide;
  }

  closeOverlay(): void {
    this.openSide = false;
  }

  setActive(menuItem: string): void {
    console.log('menuItem', menuItem)
    this.activeItem = this.activeItem === menuItem ? '' : menuItem;
  }

  isActive(menuItem: string): boolean {
    return this.activeItem === menuItem;
  }

  setChildActive(childItem: string): void {
    this.activeChildItem = this.activeChildItem === childItem ? '' : childItem;
  }

  isChildActive(childItem: string): boolean {
    return this.activeChildItem === childItem;
  }
}
