<div class="carousel-container">
  
    <div class="swiper">
      <div class="swiper-wrapper">
        <!-- Repita os itens dinamicamente com *ngFor -->
        <div class="swiper-slide" *ngFor="let item of items">
          <div class="card">
            <img [src]="item.icon" [alt]="item.title">
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!-- Botões de navegação -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>