import { AfterViewInit, Component } from '@angular/core';
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

@Component({
  selector: 'app-carousel-conteudo',
  templateUrl: './carousel-conteudo.component.html',
  styleUrls: ['./carousel-conteudo.component.scss'], // Corrigi o atributo para 'styleUrls' (plural)
})
export class CarouselConteudoComponent implements AfterViewInit {
  items = [
    { icon: 'assets/images/services/transparencia.png', title: 'Portal Transparência' },
    { icon: 'assets/images/services/bate-papo-online.png', title: 'Ouvidoria Geral' },
    { icon: 'assets/images/services/passe-de-imprensa.png', title: 'Contato à Imprensa' },
    { icon: 'assets/images/services/estacao-de-servico.png', title: 'Carta de Serviço' },
    { icon: 'assets/images/services/livro-de-leis.png', title: 'Leis Municipais' },
    { icon: 'assets/images/services/mashup-logo.svg', title: 'Radar Transparência' },
  ];

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    new Swiper('.swiper', {
      slidesPerView: 3, // Exibir 3 slides por vez em telas grandes
      spaceBetween: 20, // Espaçamento entre os slides
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true, // Ativa o looping contínuo
      breakpoints: {
        640: {
          slidesPerView: 1, // Exibir 1 slide por vez em telas menores que 640px
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2, // Exibir 2 slides por vez em telas entre 640px e 768px
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 3, // Exibir 3 slides por vez em telas maiores que 1024px
          spaceBetween: 20,
        },
      },
    });
  }
}
