import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrl: './news-preview.component.scss'
})
export class NewsPreviewComponent {
  images: string[] = [
    'assets/img1.jpg',
    'assets/img2.jpg',
    'assets/img3.jpg'
  ];
  newsSelected: any = null
  dados:any
  displayDetailNews: boolean = false
news:any[] = [
  {
    titulo: 'Demanda Popular',
    image: 'assets/images/news/demanda-popular.png',
    detail: `Câmara convoca secretário  sobre andamendo do loteamento Sant'Ana`,
    descricao: `Câmara convoca secretário sobre andamendo do loteamento Sant'Ana
                        O presidente da Câmara Municipal de Chapada, vereador Zé Otávio, juntamente com os vereadores
                            Ademir e Ronadil convidam a população de Chapada dos Guimarães para participar da sessão no
                            próximo dia 20 de fevereiro, para ouvir o posicionamento do secretário de Planejamento,
                            Aislan Galvão, sobre o loteamento Sant'Ana.
                            A primeira etapa foi sorteada ainda em 2023 e até o momento os lotes não foram entregues.`,
                            iframe: `<iframe width="560" height="315" src="https://www.youtube.com/embed/2lMUHlHX1b0?si=wuF8Qt7xWuDKRnoc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>` 
  }
]

selectedImage: string | null = null;
  
constructor(
  private route: ActivatedRoute
){

}

    ngOnInit() {
     if(this.route.snapshot.paramMap.get('nome') !== 'todas'){
        let ret = this.news.find(x => x.titulo == this.route.snapshot.paramMap.get('nome'))
        this.selectNews(ret)
     }

    }

    selectNews(event){
this.newsSelected = event
    }

    openImage(img: string) {
      this.selectedImage = img;
    }
  
    closeImage() {
      this.selectedImage = null;
    }
}
