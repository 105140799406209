import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      }
    });
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children = route.children;

    for (const child of children) {
      const routeConfig = child.routeConfig;
      if (!routeConfig || !routeConfig.path) continue;

      const newUrl = `${url}/${routeConfig.path}`;
      const breadcrumbLabel = routeConfig.data?.['breadcrumb'] || routeConfig.path;
      
      breadcrumbs.push({ label: breadcrumbLabel, url: newUrl });

      return this.createBreadcrumbs(child, newUrl, breadcrumbs);
    }
console.log('breadcrumbs', breadcrumbs)
    return breadcrumbs;
  }
}
