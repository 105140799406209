<!--footer start-->
<footer style="background-color: #04185b;">

    <div class="container">
        <div class="row">



            <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                <div class="col-lg-4 col-md-3 col-sm-12 pd10">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">A câmara</h3>
                    </div>
                    <div class="footer-contant footer-menu">
                        <h5 class="footer-headings">A câmara</h5>
                        <div>
                            <ul class="footer-lists op-text">
                                <div class="row">
                                    <div class="col-6">
                                        <li><a href="#">Agenda</a></li>
                                        <li><a href="#">História da Câmara</a></li>
                                        <li><a href="#">Competência, Jurisdição e Atribuição</a></li>
                                        <li><a href="#">Equipe do Legislativo</a></li>
                                        <li><a href="#">Organograma</a></li>
                                    </div>
                                    <div class="col-6">
                                        <li><a href="#">Controle interno</a></li>
                                        <li><a href="#">Normas e regimento interno</a></li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-3 col-sm-12 pd10">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Vereadores</h3>
                    </div>
                    <div class="footer-contant footer-menu">
                        <h5 class="footer-headings">Vereadores</h5>
                        <div>
                            <ul class="footer-lists op-text">
                                <div class="row">
                                    <div class="col-6">
                                        <li><a href="#">Atual Legislatura</a></li>
                                        <li><a href="#">Mesa Diretora</a></li>
                                        <li><a href="#">Suplentes</a></li>
                                        <li><a href="#">Legislaturas Anteriores</a></li>
                                        <li><a href="#">Comissões Permanentes</a></li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-3 col-sm-12 pd10">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Legislações</h3>
                    </div>
                    <div class="footer-contant footer-menu">
                        <h5 class="footer-headings">Legislações</h5>
                        <div>
                            <ul class="footer-lists op-text">
                                <div class="row">
                                    <div class="col-6">
                                        <li><a href="#">Leis Municipais</a></li>
                                        <li><a href="#">Atas das Sessões</a></li>
                                        <li><a href="#">Ato Legislativo</a></li>
                                        <li><a href="#">Criação da Ouvidoria</a></li>
                                        <li><a href="#">Decretos</a></li>
                                    </div>
                                    <div class="col-6">
                                        <li><a href="#">Lei Complementar</a></li>
                                        <li><a href="#">Leis Estaduais</a></li>
                                        <li><a href="#">Leis Federais</a></li>
                                        <li><a href="#">Leis Ordinárias</a></li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-3 col-sm-12 pd10">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Publicações</h3>
                    </div>
                    <div class="footer-contant footer-menu">
                        <h5 class="footer-headings">Publicações</h5>
                        <div>
                            <ul class="footer-lists op-text">
                                <div class="row">
                                    <div class="col-6">
                                        <li><a href="#">Licitações</a></li>
                                        <li><a href="#">Balancetes Mensais</a></li>
                                        <li><a href="#">Balanço Anual</a></li>
                                        <li><a href="#">Concurso Público</a></li>
                                        <li><a href="#">Contratos e Aditivos</a></li>
                                    </div>
                                    <div class="col-6">
                                        <li><a href="#">Emendas</a></li>
                                        <li><a href="#">Gestão de Pessoas</a></li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-3 col-sm-12 pd10">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Imprensa</h3>
                    </div>
                    <div class="footer-contant footer-menu">
                        <h5 class="footer-headings">Imprensa</h5>
                        <div>
                            <ul class="footer-lists op-text">
                                <div class="row">
                                    <div class="col-6">
                                        <li><a href="#">Notícias</a></li>
                                        <li><a href="#">Banco de Imagens</a></li>
                                        <li><a href="#">Galeria de Vídeos</a></li>
                                    </div>
                                    <div class="col-6">
                                        <li><a href="#">Rádio Câmara</a></li>
                                        <li><a href="#">Sessão ao vivo</a></li>
                                        <li><a href="#">Contato</a></li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-3 col-sm-12 pd10">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Cidade</h3>
                    </div>
                    <div class="footer-contant footer-menu">
                        <h5 class="footer-headings">Cidade</h5>
                        <div>
                            <ul class="footer-lists op-text">
                                <div class="row">
                                    <div class="col-6">
                                        <li><a href="#">Economia</a></li>
                                        <li><a href="#">História do Município</a></li>
                                        <li><a href="#">Geografia</a></li>
                                    </div>
                                    <div class="col-6">
                                        <li><a href="#">Imagens do Município</a></li>
                                        <li><a href="#">Símbolos Municipal</a></li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>





        </div>
        <!--footer end-->
        <div class="row mt-40">
            <div class="col-12">
                <div class="brand-container">
                    <div class="row">
                        <div class="col-12 text-center mt-24">
                            <h6>Links Úteis</h6>
                        </div>
                        <div class="col-12 text-center">
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/logo-almt.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/logo-amm.svg" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/logo-mt.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a href="#" class="center-content">
                                            <img src="assets/images/logomarca_tce.png" alt="" class="img-fluid">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 mt-40">
                <div class="logo-sec text-center">
                    <div class="footer-contant">
                        <img src="assets/images/camara-chapada-branca-sem-fundo.png" alt=""
                            class="img-fluid footer-logo" style="width: 400px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Câmara Municipal de Chapada dos Guimarães - MT</h6>
                            <h6 class="text-white para-address">Av. Fernando Corrêa, Centro, n°. 763, CEP: 78.195-000,
                            </h6>
                            <h6 class="text-white para-address"
                                [innerHTML]="'administrativo@camarachapadadosguimaraes.mt.gov.br'"></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>


<!--footer end-->
<app-tap-to-top></app-tap-to-top>