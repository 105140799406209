import { Component } from '@angular/core';

@Component({
  selector: 'app-vereadores-preview',
  templateUrl: './vereadores-preview.component.html',
  styleUrl: './vereadores-preview.component.scss'
})
export class VereadoresPreviewComponent {

}
