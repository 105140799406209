<a
  class="overlay-sidebar-header"
  [class.overlay-sidebar-header-open]="openSide"
  (click)="closeOverlay()"
  aria-label="Logo da Câmara Municipal de Chapada dos Guimarães"
></a>

<div class="responsive-btn">
  <button
    (click)="toggleSidebar()"
    class="btn-menu"
    aria-label="Abrir o menu"
  >
    <i
      style="background-color: white; color: #10266b !important;"
      class="fa fa-bars text-white"
      aria-hidden="true"
    ></i>
  </button>
</div>

<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
  <div class="responsive-btn">
    <button
      class="btn-voltar"
      (click)="toggleSidebar()"
      aria-label="Fechar o menu"
    >
      <h5>Voltar</h5>
    </button>
  </div>

  <ul class="main-menu">
    <li
      *ngFor="let menuItem of menuItems"
      [ngClass]="{ 'mega-menu': menuItem.megaMenu }"
    >
      <ng-container [ngSwitch]="menuItem.type">
        <a
          *ngSwitchCase="'sub'"
          class="dropdown"
          [routerLink]="menuItem.path"
          (click)="setActive(menuItem.title)"
          [attr.aria-label]="menuItem.title"
        >
          {{ menuItem.title }}
        </a>

        <a
          *ngSwitchCase="'alone'"
          [routerLink]="menuItem.path"
          (click)="setActive(menuItem.title)"
          [attr.aria-label]="menuItem.title"
        >
          {{ menuItem.title }}
        </a>

        <a
          *ngSwitchCase="'link'"
          [routerLink]="menuItem.path"
          routerLinkActive="active"
          class="dropdown"
          [attr.aria-label]="menuItem.title"
        >
          {{ menuItem.title }}
        </a>

        <a
          *ngSwitchCase="'extLink'"
          href="{{ menuItem.path }}"
          class="dropdown"
          target="_blank"
          rel="noopener noreferrer"
          [attr.aria-label]="menuItem.title"
        >
          {{ menuItem.title }}
        </a>
      </ng-container>

      <div
        *ngIf="menuItem.megaMenu"
        class="mega-menu-container"
        [ngClass]="{ opensubmenu: isActive(menuItem.title) }"
      >
        <div class="container">
          <div class="row">
            <div
              *ngFor="let childrenItem of menuItem.children"
              [ngClass]="{
                'col-lg-4': menuItem.megaMenuType === 'small',
                'col-lg-3': menuItem.megaMenuType === 'medium',
                'col-lg-12': menuItem.megaMenuType === 'large'
              }"
            >
              <div class="menu-container">
                <a
                  class="menu-head"
                  [routerLink]="childrenItem.path"
                  (click)="setChildActive(childrenItem.title)"
                  [attr.aria-label]="childrenItem.title"
                >
                  {{ childrenItem.title }}
                </a>
                <ul
                  [ngClass]="{
                    openSubChildMenu: isChildActive(childrenItem.title)
                  }"
                >
                  <li *ngFor="let subChildrenItem of childrenItem.children">
                    <a
                      *ngIf="subChildrenItem.type === 'link'"
                      [routerLink]="subChildrenItem.path"
                      routerLinkActive="active"
                      [attr.aria-label]="subChildrenItem.title"
                    >
                      <i class="icon-{{ subChildrenItem.icon }}"></i>
                      {{ subChildrenItem.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
