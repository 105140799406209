<div class="container">
  <!-- NOTICIAS -->
  <div class="row">
    <div class="col-lg-6 col-sm-12">
      <div class="featured-news">
        <h2>
          <span class="icon">
            <i class="fa fa-file-text"></i>
          </span>
          Notícia em destaque
        </h2>
        <div (click)="selectNews()" class="news-card-destaque mt-24">
          <img src="assets/images/news/demanda-popular.png" alt="Imagem da notícia em destaque" />
          <div class="news-content-destaque">
            <h3>Demanda popular</h3>
            <p>Câmara convoca secretário sobre andamendo do loteamento Sant'Ana</p>
            <a class="mt-8" href="#">Acessar notícia →</a>
          </div>
        </div>
      </div>

    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="latest-news">
        <h2>
          <span class="icon">
            <i class="fa fa-newspaper"></i>
          </span>
          Últimas notícias
        </h2>

        <div class="news-list mt-24">

          <div class="news-card">
            <div class="text-content">
              <h3>Nova Legislatura</h3>
              <a href="#">Acessar notícia →</a>
            </div>
            <div class="image-content">
              <img src="assets/images/news/nova-legislatura.png" alt="Imagem da notícia" />
            </div>
          </div>
          <div class="news-card">
            <div class="text-content">
              <h3>Prefeitura de Chapada dos Guimarães cria Secretaria Municipal de Previdência</h3>
              <a href="#">Acessar notícia →</a>
            </div>
            <div class="image-content">
              <img src="assets/images/news/aprovada-na-camara.png" alt="Imagem da notícia" />
            </div>
          </div>
          <div class="news-card">
            <div class="text-content">
              <h3>Câmara Municipal de Chapada dos Guimarães aprova lei para criação do Sistema de Segurança Alimentar
              </h3>
              <a href="#">Acessar notícia →</a>
            </div>
            <div class="image-content">
              <img src="assets/images/news/sancionada.png" alt="Imagem da notícia" />
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="col-lg-12 col-sm-12">
      <a href="https://www.saopaulo.sp.leg.br/comunicacao/noticias"
        class="w100 button-primary mt-24 text-decoration-none">
        Clique para acessar todas as notícias
      </a>
    </div>

    <div class="col-lg-6 col-sm-12 mt-40">
      <div class="featured-news">
        <h2>
          <span class="icon">
            <i class="fa fa-calendar"></i>
          </span>
          Próximos eventos
        </h2>
        <div class="news-card-destaque mt-24">
          <img src="assets/images/news/nova-legislatura.png" alt="Imagem da notícia em destaque" />
          <div class="news-content-destaque">
            <h3>Coletar assinaturaas</h3>
            <p class="mt-8">Horário: 00:00</p>
            <p class="mt-8">Local: Chapada dos Guimarães</p>
            <button class="button-primary mt-8">
              Cadastrar na agenda
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="col-lg-12 col-sm-12">
      <a href="https://www.saopaulo.sp.leg.br/comunicacao/noticias"
        class="w100 button-primary mt-24 text-decoration-none">
        Clique para acessar a agenda completa
      </a>
    </div>
  </div>


  <!-- VEREADORES -->
  <div class="col-lg-12 col-sm-12 mt-40">
    <h2>
      <span class="icon">
        <i class="fa fa-users"></i>
      </span>
      Vereadores
    </h2>
    <app-agency-speaker></app-agency-speaker>

  </div>

</div>



<section class="tv-radio">
  <div class="container">
    <div class="img">
      <img class="center-block img-fluid img-banner" style="width: 200px;" src="assets/images/brasao.png" alt="Banner">
    </div>
    <div class="row w100 mt-40">
      <div class="col-lg-6 col-sm-12">
        <h2>
          <span class="icon">
            <i class="fa fa-television"></i>
          </span>
          Assita a TV Câmara
        </h2>
        <div class="ao-vivo border-r-w">
          <div
            style="cursor:pointer;border-top-left-radius:16px;border-top-right-radius:16px;background-color: black;position: relative"
            class="click-rede"
            onclick="jQuery('.jmvplayer').attr('src', jQuery('.jmvplayer').attr('data-src')).css('pointer-events', 'all');jQuery('.click-rede img').detach();jQuery('.click-rede img').removeAttr('onclick')">
            <iframe class="jmvplayer"
              style="width: 100%; aspect-ratio: 16 / 9; height: 100%; display: block; border-top-right-radius: 16px; border-top-left-radius: 16px; pointer-events: all;"
              data-src="https://www.youtube.com/embed/?rel=0&amp;showinfo=0" allowfullscreen=""
              allow="autoplay; fullscreen" frameborder="0" width="50%" height="351" scrolling="yes"
              data-gtm-yt-inspected-3="true" src="https://www.youtube.com/embed/?rel=0&amp;showinfo=0"></iframe>


          </div>
          <div class="ph-12 pv-18 bk-white b-b-16s">
            <h3 style=" font-size: 1rem !important;
             margin: 0 0 8px 0 !important;
             color: #000 !important;">
              Acompanhe a Rede Câmara de Chapada dos Guimarães AO VIVO </h3>



            <a style="color: #1f357d;
                 text-decoration: none;" class="flex g-13 align-center span" href="#">
              Acessar página do Video →
            </a>

          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <h2>
          <span class="icon">
            <i class="fa fa-microphone"></i>
          </span>
          Ouça a Rádio Câmara
        </h2>
        <div class="row">
          <div class="col-12 margin-heigth border-r-w" style="padding: 47px;">
            <div class="card-radio">
              <div class="card-body body-audio">
                <div class="no-gutters">
                  <div class="col-12 col-box-audio-item">
                    <a href="Imprensa/Radio-Camara/Hino-de-chapada-dos-guimaraes-4">
                      <div class="card-title titulo-audio">
                        Hino de Chapada dos Guimarães </div>
                      <div class="no-gutters">
                        <audio controls="controls" class="col-12">
                          <source class="embed-responsive-item" src="fotos_audios/4.mp3" type="audio/mp3">
                          <source class="embed-responsive-item" src="fotos_audios/4.mp3" type="audio/wav">
                          <source class="embed-responsive-item" src="fotos_audios/4.mp3" type="audio/ogg">
                          <source class="embed-responsive-item" src="fotos_audios/4.mp3" type="audio/mpeg">
                        </audio>
                      </div>
                    </a>
                  </div>
                  <div>
                    <hr class="hr-audio">
                  </div>
                  <div class="col-12 col-box-audio-item">
                    <a href="Imprensa/Radio-Camara/Hino-de-mato-grosso-3">
                      <div class="card-title titulo-audio">
                        Hino de Mato Grosso </div>
                      <div class="no-gutters">
                        <audio controls="controls" class="col-12">
                          <source class="embed-responsive-item" src="fotos_audios/3.mp3" type="audio/mp3">
                          <source class="embed-responsive-item" src="fotos_audios/3.mp3" type="audio/wav">
                          <source class="embed-responsive-item" src="fotos_audios/3.mp3" type="audio/ogg">
                          <source class="embed-responsive-item" src="fotos_audios/3.mp3" type="audio/mpeg">
                        </audio>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <a style="color: #1f357d;
                  text-decoration: none;" class="flex g-13 align-center span" href="#">
                Acessar página da Rádiio →
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>