import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-speaker',
  templateUrl: './agency-speaker.component.html',
  styleUrls: ['./agency-speaker.component.scss']
})
export class AgencySpeakerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  vereadores = [
    { 
     img:"assets/images/vereadores/ronadil.jpg",
     name:"Ronadil Amorin",
     designation:"PP"
    },
    { 
      img:"assets/images/vereadores/ademir.jpg",
      name:"Ademir do sindicato",
      designation:"PSDB"
     },
     { 
      img:"assets/images/vereadores/kinho.jpg",
      name:"Kinho",
      designation:"União Brasil"
     },
     { 
      img:"assets/images/vereadores/angela.jpg",
      name:"Angela Brito",
      designation:"PL"
     },
     { 
      img:"assets/images/vereadores/cida.jpg",
      name:"Cida Lessa",
      designation:"PSD"
     },
     { 
      img:"assets/images/vereadores/professor.jpg",
      name:"Professor Paulinho",
      designation:"PSD"
     },
     { 
      img:"assets/images/vereadores/carlinhos.jpg",
      name:"Carlinhos da distribuidora",
      designation:"PP"
     },
     { 
      img:"assets/images/vereadores/gilberto.jpg",
      name:"Gilberto Mello",
      designation:"PL"
     },
     { 
      img:"assets/images/vereadores/joair.jpg",
      name:"Joair Siqueira",
      designation:"União Brasil"
     },
     { 
      img:"assets/images/vereadores/ze.jpg",
      name:"Zé Otávio",
      designation:"PL"
     },
     { 
      img:"assets/images/vereadores/rafael.jpg",
      name:"Rafael Nilo",
      designation:"PSD"
     },
  ]
  
  speakerCarouselOptions = { 
    items: 4,
    margin: 20, // Reduz a margem entre os itens para caberem melhor
    nav: false,
    dots: true,
    slideSpeed: 600,
    paginationSpeed: 600,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000, // Tempo entre cada slide (5000ms = 5 segundos)
    autoplaySpeed: 1000, // Tempo de transição entre slides (1000ms = 1 segundo)
    smartSpeed: 1000, // Torna a animação mais suave (1000ms = 1 segundo)
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        480: {
            items: 2,
            margin: 10
        },
        768: {
            items: 3,
            margin: 15
        },
        1024: {
            items: 4,
            margin: 20
        }
    }
};



}
