<!-- BANNER PRINCIPAL -->
<div class="container">
    <div class="row">
        <img class="center-block img-fluid img-banner" src="assets/images/banner-camara-chapada.png" alt="Banner">
    </div>
</div>

<!-- BANNERS -->

<div class="row row-banners">
    <div class="container container-banners">
        <div class="row pd">
            <div class="col-lg-6 col-sm-12 col-box-banner pd">
                <a href="https://ouvidoria.camarachapadadosguimaraes.mt.gov.br/Carta-Servicos/" target="_blank">
                    <img class="center-block img-fluid img-banner" src="assets/images/carta-servicos.png" alt="Banner">
                </a>
            </div>
            <div class="col-lg-6 col-sm-12 col-box-banner pd">
                <a href="https://www.gp.srv.br/transparencia_chapada/servlet/home_portal_v2?1" target="_blank">
                    <img class="center-block img-fluid img-banner" src="assets/images/portal-transparencia.png"
                        alt="Banner">
                </a>
            </div>

        </div>


    </div>
</div>