<!--copyright start-->
<div class="agency copyright">
        <div class="container">
            <div class="row">
                
                <div class="col-12" >
                        <h6 class="copyright-text text-white text-center">Copyright © 2025 Todos os direitos reservados - Câmara Municipal de Chapada dos Guimarães
                        </h6>
                    
                </div>
            </div>
        </div>
    </div>
    <!--copyright start-->
    