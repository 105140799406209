import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
	{ path: '', title: 'Home', megaMenu: false, type: 'alone' },
    {
			title: 'A Câmara', megaMenu: true, megaMenuType: 'small', type: 'sub', children: [
        { path: '/camara', title: 'Agenda da Câmara',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'História da Câmara',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Competência, Jurisdição e Atribuição',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Equipe do Legislativo',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Organograma',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Controle Interno',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Normas e Regimento Interno',  type: 'link' },
      ]
    },
    {
			title: 'Vereadores', path: '/vereadores/categorias', megaMenu: false, megaMenuType: 'small', type: 'alone', children: [
        { path: '/portfolio/basic-2-grid', title: 'Atual Legislatura',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Mesa Diretora',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Suplentes Atual Legislatura',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Legislaturas Anteriores',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Comissões Permanentes',  type: 'link' },
      ]
    },
    { 
      title: 'Legislações',  megaMenu: true, megaMenuType: 'small',  type: 'sub', children: [
        { path: '/portfolio/basic-2-grid', title: 'Leis Municipais',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Atas das Sessões',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Ato Legislativo',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Criação da Ouvidoria',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Decretos',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Lei Complementar',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Leis Estaduais',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Leis Federais',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Leis Ordinárias',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Leis Orgânicas',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Pautas das Sessões',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Portarias',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Regimento Interno',  type: 'link' },
      ]
    },
    { 
      title: 'Publicações',  megaMenu: true, megaMenuType: 'small',  type: 'sub', children: [
        { path: '/portfolio/basic-2-grid', title: 'Licitações',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Balancetes Mensais',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Balanço Anual',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Concurso Público',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Contratos e Aditivos',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Emendas',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Gestão de Pessoas',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Indicações',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Lei de Acesso a Informação',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Moções',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Projeto de Leis',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Requerimentos',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Resoluções',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'RGF',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'LDO',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'LOA',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'RPA',  type: 'link' },
      ]
    },
    { 
      title: 'Imprensa',  megaMenu: true, megaMenuType: 'small',  type: 'sub', children: [
        { path: '/noticias/todas', title: 'Notícias',  type: 'link' },
        { path: '/galeria-imagens', title: 'Banco de Imagens',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Galeria de Videos',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'TV Câmara',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Rádio Câmara',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Sessão ao vivo',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Contato',  type: 'link' },
      ]
    },
    { 
      title: 'Cidade',  megaMenu: true, megaMenuType: 'small',  type: 'sub', children: [
        { path: '/portfolio/basic-2-grid', title: 'Economia',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'História do Município',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Geografia',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Imagens do Município',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Simbolo Municipal',  type: 'link' },
        { path: '/portfolio/basic-2-grid', title: 'Hino do Município',  type: 'link' },
      ]
    },
   //  { 
   //    title: 'Portfolio',  megaMenu: true, megaMenuType: 'medium',  type: 'sub', children: [
   //     { title: 'Portfolio-Basic',  type: 'link', children: [
   //        { path: '/portfolio/basic-2-grid', title: 'Basic – 2 Grid',  type: 'link' },
   //        { path: '/portfolio/basic-3-grid', title: 'Basic – 3 Grid',  type: 'link' },
   //        { path: '/portfolio/basic-4-grid', title: 'Basic – 4 Grid',  type: 'link' },
   //        { path: '/portfolio/portfolio-title-2-col', title: 'Basic W Tittle – 2 Grid',  type: 'link' },
   //        { path: '/portfolio/portfolio-title-3-col', title: 'Basic W Tittle – 3 Grid',  type: 'link' },
   //        { path: '/portfolio/portfolio-title-4-col', title: 'Basic W Tittle – 4 Grid',  type: 'link' },
   //        { path: '/portfolio/portfolio-parallex', title: 'Parallex',  type: 'link' },
   //        { path: '/portfolio/centered-slide', title: 'Centered Slides',  type: 'link' },
   //        { path: '/portfolio/vertical-slide', title: 'Vertical Slides',  type: 'link' },
   //        { path: '/portfolio/multiple-carousel', title: '4 Slide With Center Slider',  type: 'link' }]
   //     },
   //     { title: 'Portfolio-Details',  type: 'link', children: [
   //       { path: '/portfolio/portfolio-detail-1', title: 'Container Layout',  type: 'link' },
   //       { path: '/portfolio/portfolio-detail-2', title: 'Full Width',  type: 'link' },
   //       { path: '/portfolio/portfolio-detail-3', title: 'With Bg Bredcrumb',  type: 'link' },
   //       { path: '/portfolio/portfolio-detail-4', title: 'Video Portfolio',  type: 'link' }, 
   //       { path: '/portfolio/portfolio-detail-5', title: 'Two Image Portfolio',  type: 'link' }, 
   //       { path: '/portfolio/portfolio-detail-6', title: 'Left Side Image Portfolio',  type: 'link' }]
   //    },
   //    { title: 'Trending Layout',  type: 'link', children: [
   //       { path: '/portfolio/creative-1', title: 'Trending Layout 1',  type: 'link' },
   //       { path: '/portfolio/creative-2', title: 'Trending Layout 2',  type: 'link' },
   //       { path: '/portfolio/creative-3', title: 'Trending Layout 3',  type: 'link' },
   //       { path: '/portfolio/creative-4', title: 'Trending Layout 4',  type: 'link' },
   //       { path: '/portfolio/modern-4', title: 'Trending Layout 5',  type: 'link' },
   //       { path: '/portfolio/modern-3', title: 'Trending Layout 6',  type: 'link' },
   //       { path: '/portfolio/modern-2', title: 'Trending Layout 7',  type: 'link' }]
   //    },
   //     { title: 'Portfolio-Masonary',  type: 'link', children: [
   //       { path: '/portfolio/full-width-2-grid', title: 'Full-Width – 2 Grid',  type: 'link' },
   //       { path: '/portfolio/full-width-3-grid', title: 'Full-Width – 3 Grid',  type: 'link' },
   //       { path: '/portfolio/full-width-4-grid', title: 'Full-Width – 4 Grid',  type: 'link' }]
   //     },
   //    ]
   //  },
   //  { 
   //    title: 'Shop',  megaMenu: true, megaMenuType: 'large',  type: 'sub', children: [
   //     { title: 'Shop Categories',  type: 'link', children: [
   //        { path: '/shop/collection-left-sidebar/all', title: 'Left Sidebar',  type: 'link' },
   //        { path: '/shop/collection-left-sidebar-two-grid/all', title: 'Two Grid',  type: 'link' },
   //        { path: '/shop/collection-left-sidebar-three-grid/all', title: 'Three Grid',  type: 'link' },
   //        { path: '/shop/collection-right-sidebar/all', title: 'Right Sidebar',  type: 'link' },
   //        { path: '/shop/collection-right-sidebar-two-grid/all', title: 'Two Grid',  type: 'link' },
   //        { path: '/shop/collection-right-sidebar-three-grid/all', title: 'Three Grid',  type: 'link' }]
   //     },
   //     { title: 'Shop Categories',  type: 'link', children: [
   //       { path: '/shop/collection-no-sidebar/all', title: 'No Sidebar',  type: 'link' },
   //       { path: '/shop/collection-no-sidebar-two-grid/all', title: 'Two Grid',  type: 'link' },
   //       { path: '/shop/collection-no-sidebar-three-grid/all', title: 'Three Grid',  type: 'link' },
   //       { path: '/shop/metro/all', title: 'Category Metro',  type: 'link' }]
   //     },
   //     { title: 'Product Pages',  type: 'link', children: [
   //        { path: '/shop/no-sidebar/1', title: 'No Sidebar',  type: 'link' },
   //        { path: '/shop/left-sidebar/2', title: 'Left Sidebar',  type: 'link' },
   //        { path: '/shop/right-sidebar/3', title: 'Right Sidebar',  type: 'link' },
   //        { path: '/shop/three-grid/1', title: 'Three Grid',  type: 'link' },
   //        { path: '/shop/three-grid-left-sidebar/2', title: 'Three Grid Left',  type: 'link' },
   //        { path: '/shop/three-grid-right-sidebar/3', title: 'Three Grid Right',  type: 'link' }]
   //     },
   //     { title: 'Product Pages',  type: 'link', children: [
   //        { path: '/shop/accordian/1', title: 'Accordian',  type: 'link' },
   //        { path: '/shop/bundle/1', title: 'Bundle',  type: 'link' },
   //        { path: '/shop/image-swatch/1', title: 'Image Swatch',  type: 'link' },
   //        { path: '/shop/image-outside/1', title: 'Image Outside',  type: 'link' },
   //        { path: '/shop/image-sticky/1', title: 'Image Sticky',  type: 'link' }]
   //     },
   //     { title: 'Shop Pages',  type: 'link', children: [
   //      { path: '/shop/cart', title: 'Cart',  type: 'link' },
   //      { path: '/shop/checkout', title: 'Checkout',  type: 'link' },
   //      { path: '/shop/compare', title: 'Comapre',  type: 'link' },
   //      { path: '/shop/signup', title: 'Signup',  type: 'link' },
   //      { path: '/shop/login', title: 'Login',  type: 'link' },
   //      { path: '/shop/wishlist', title: 'Wishlist',  type: 'link' }]
   //    }
   //    ]
   //  },
   //  { 
   //    title: 'Features',  megaMenu: true, megaMenuType: 'medium',  type: 'sub', children: [
   //     { title: 'Header Style',  type: 'link', children: [
   //       { path: '/features/header-light', title: 'Light Header',  type: 'link' },
   //       { path: '/features/header-dark', title: 'Dark Header',  type: 'link' },
   //        { path: '/features/header-transparent', title: 'Glass Header',  type: 'link' },
   //        { path: '/features/header-right-navigation', title: 'Header Right Navigation',  type: 'link' },
   //        { path: '/features/header-center-logo', title: 'Header Center Logo',  type: 'link' }]
   //     },
   //     { title: 'Breadcrumb Style',  type: 'link', children: [
   //        { path: '/features/breadcrumb-big-typography', title: 'Classic Type',  type: 'link' },
   //        { path: '/features/breadcrumb-left', title: 'Breadcrumb Left',  type: 'link' },
   //        { path: '/features/breadcrumb-right', title: 'Breadcrumb Right',  type: 'link' },
   //        { path: '/features/breadcrumb-center', title: 'Breadcrumb Center',  type: 'link' },
   //        { path: '/features/breadcrumb-dark', title: 'Breadcrumb Dark',  type: 'link' },
   //        { path: '/features/breadcrumb-parallex-bg', title: 'Breadcrumb Parallex-Bg',  type: 'link' },
   //        { path: '/features/breadcrumb-bg', title: 'Breadcrumb Bg',  type: 'link' },
   //        { path: '/features/breadcrumb-gallery', title: 'Breadcrumb Gallery',  type: 'link' },
   //        { path: '/features/breadcrumb-video', title: 'Breadcrumb Video',  type: 'link' }]
   //     },
   //     { title: 'Footer Style',  type: 'link', children: [
   //       { path: '/features/footer-default', title: 'Footer-Default',  type: 'link' },
   //       { path: '/features/footer-light', title: 'Footer-Light',  type: 'link' },
   //       { path: '/features/footer-dark', title: 'Footer-Dark',  type: 'link' },
   //       { path: '/features/footer-light2', title: 'Footer Dark Light',  type: 'link' },
   //       { path: '/features/footer-dark2', title: 'Footer Dark Gradient',  type: 'link' },
   //       { path: '/features/footer-dark3', title: 'Footer Creative',  type: 'link' }]

   //    },
   //     { title: 'Gallery',  type: 'link', children: [
   //        { path: '/features/zoom-gallery', title: 'Zoom Gallery',  type: 'link' },
   //        { path: '/features/form-popup', title: 'Form Popup',  type: 'link' },
   //        { path: '/features/modal-popup', title: 'Modal Popup',  type: 'link' },
   //        { path: '/features/youtube-popup', title: 'Youtube Popup',  type: 'link' },
   //        { path: '/features/map-popup', title: 'Map Popup',  type: 'link' }]
   //     }
   //    ]
   //  },
   ]
  
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
   
}
