<app-agency-nav></app-agency-nav>


<div class="abs">
    <div class="container">
        <div class="desktop-body-3">
            <ul class="breadcrumb" style="background: white;">
                <li><a href="#">Home</a></li>
                <li>/</li>
                <li><strong>Banco De Imagens</strong></li>
            </ul>

        </div>
    </div>

</div>
<section>
    <div class="container">
        <div class="gallery">
            <div class="main-image">
                <img [src]="selectedImage" alt="Imagem Selecionada">
            </div>
            <div class="thumbnails">
                <img *ngFor="let img of images" [src]="img" alt="Thumbnail" [class.active]="img === selectedImage"
                    (click)="selectImage(img)">
            </div>
        </div>
    </div>
</section>

<div class="w100 mt-32" style="background-color: #223988;">
    <div class="container" style="padding: 15px;">
        <div class="row w100 atendimento">
            <div class="col-lg-2 col-md-12 col-sm-12">
                <h3 class="text-white">Atendimento:</h3>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 text-center">
                <h6 class="text-white">
                    (65) 3301-1150
                </h6>
                <h6 class="text-white">Das 13:00hs ás 18:00hs
                </h6>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 text-center">
                <h6 class="text-white">Câmara Municipal de Chapada dos Guimarães - MT</h6>
                <h6 class="text-white">Av. Fernando Corrêa, Centro, n°. 763, CEP: 78.195-000, </h6>
                <a class="text-white" href="mailto:administrativo&#64;camarachapadadosguimaraes.mt.gov.br">
                    <span>administrativo&#64;camarachapadadosguimaraes.mt.gov.br</span>
                </a>
            </div>

        </div>
    </div>

</div>

<app-agency-footer></app-agency-footer>
<app-agency-copyright></app-agency-copyright>