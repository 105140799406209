<div *ngIf="abaSelected == null" class="abs">
  <div class="container">
    <div class="desktop-body-3">
      <ul class="breadcrumb" style="background: white;">
        <li><a href="#">Home</a></li>
        <li>/</li>
        <li><strong>Vereadores</strong></li>
    </ul>
  </div>
  </div>
 
</div>
<section *ngIf="abaSelected !== null" id="cover-presidente-camara" class="w100">
  <div class="config-image-bg w100">
                          <img *ngIf="abaSelected !== null" src="assets/images/chapada-banner.png" class="w100">
                      <div class="abs mt-32">
          <div class="container">
              <div class="desktop-body-3">
                  <ul>
                      <li><a href="#">Home</a></li>
                      <li>/</li>
                      <li>Vereadores</li>
                      <li>/</li>
                      <li *ngIf="abaSelected !== null"><strong>{{abaSelected}}</strong></li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</section>
<section>
  <div class="container">
    <h1 *ngIf="abaSelected == null" class="desktop-headeline-2 mt-44">Vereadores</h1>
    <div *ngIf="abaSelected == null" class="row mt-44">
        <div class="col-lg-6 col-md-6 col-sm-12 pd">
            <div class="news-card" (click)="selectAba('Atual Legislatura')">
                <div class="text-content">
                  <h3>Atual Legislatura</h3>
                  <a href="#">Acessar página →</a>
                </div>
                <div class="image-content">
                  <img src="" alt="Imagem da area" />
                </div>
              </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 pd">
            <div class="news-card" (click)="selectAba('Legislaturas Anteriores')">
                <div class="text-content">
                  <h3>Legislaturas Anteriores</h3>
                  <a href="#">Acessar página →</a>
                </div>
                <div class="image-content">
                  <img src="" alt="Imagem da area" />
                </div>
              </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 pd">
            <div class="news-card" (click)="selectAba('Mesa Diretora')">
                <div class="text-content">
                  <h3>Mesa Diretora</h3>
                  <a href="#">Acessar página →</a>
                </div>
                <div class="image-content">
                  <img src="" alt="Imagem da area" />
                </div>
              </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 pd">
            <div class="news-card" (click)="selectAba('Comissões Permanentes')">
                <div class="text-content">
                  <h3>Comissões Permanentes</h3>
                  <a href="#">Acessar página →</a>
                </div>
                <div class="image-content">
                  <img src="" alt="Imagem da area" />
                </div>
              </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 pd">
            <div class="news-card" (click)="selectAba('Suplentes Atual Legislatura')">
                <div class="text-content">
                  <h3>Suplentes Atual Legislatura</h3>
                  <a href="#">Acessar página →</a>
                </div>
                <div class="image-content">
                  <img src="" alt="Imagem da area" />
                </div>
              </div>
        </div>
       
    </div>

    <!-- <div *ngIf="abaSelected !== null && displayDetailVereador == false" class="row w100">
        <div class="col-12 d-flex">
            <div class="col-lg-2 col-sm-12">
                <a href="javascript:void(0)" (click)="abaSelected = null" class="voltar">← Voltar</a>
            </div>
            <div class="col-lg-10 col-sm-12">
                <h3 class="titulo">{{abaSelected}}</h3>
                <p class="mt-24">Os 11 vereadores que compõem a Câmara Municipal de Chapada dos Guimarães fiscalizam o Poder Executivo, além de terem a função de criar, aprovar e modificar leis. Entre as atribuições dos parlamentares estão as discussões de temas do Município em comissões técnicas e Audiências Públicas</p>
            </div>

        </div>

        <div class="row mt-24">
            <div *ngFor="let expert of vereadores" class="pd">
                <div class="card-vereador">
                    <img [src]="expert.img" alt="" class="img-fluid">
                    <h5 class="e-name text-center font-secondary">{{expert.name}}</h5>
                            <h6 class="post text-center ">{{expert.designation}}</h6>

                            <a (click)="selectVereador(expert)" href="javascript:void(0)" class="w100 button-primary mt-24 text-decoration-none">
                                Acessar
                            </a>
                </div>
            </div>
        </div>
        
        
    </div> -->
    

</div>
</section>














<section *ngIf="abaSelected !== null && displayDetailVereador == false" id="infos-presidente-camara">
  <div class="abs">
    <div class="container aba-selecionada">
      <div class="col-12 d-flex">
        <div class="col-lg-2 col-sm-12">
            <a href="javascript:void(0)" (click)="abaSelected = null" class="voltar">← Voltar</a>
        </div>
        <div class="col-lg-10 col-sm-12">
            <h3 class="titulo">{{abaSelected}}</h3>
            <p class="mt-24">Os 11 vereadores que compõem a Câmara Municipal de Chapada dos Guimarães fiscalizam o Poder Executivo, além de terem a função de criar, aprovar e modificar leis. Entre as atribuições dos parlamentares estão as discussões de temas do Município em comissões técnicas e Audiências Públicas</p>
        </div>

    </div>
      <div class="row mt-24">
        <div *ngFor="let expert of vereadores" class="pd">
            <div class="card-vereador">
                <img [src]="expert.img" alt="" class="img-fluid">
                <h5 class="e-name text-center font-secondary">{{expert.name}}</h5>
                        <h6 class="post text-center ">{{expert.designation}}</h6>
    
                        <a (click)="selectVereador(expert)" href="javascript:void(0)" class="w100 button-primary mt-24 text-decoration-none">
                            Acessar
                        </a>
            </div>
        </div>
    </div>
      </div>
      </div>
  
</section>

<section *ngIf="displayDetailVereador" id="infos-presidente-camara">
  <div class="abs">
      <div class="container">
          <div class="sobre-presidente-camara">
              <div class="foto-perfil-presidente-camara">
                  <img width="260" height="260" [src]="dadosVereador.img" class="w100" alt="" decoding="async" fetchpriority="high"  sizes="(max-width: 260px) 100vw, 260px">                        </div>

              <h1 class="desktop-headeline-3">{{dadosVereador.name}}</h1>
              <h3 class="desktop-body-1 mt-8">{{dadosVereador.designation}}</h3>

              <div class="textos-presidente-camara mt-40">
                  <div class="coluna-esquerda desktop-body-1">
                      <h2 class="desktop-headeline-4">
                          <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/dist/images/icon-profile.svg" alt="icone">
                          Conheça o Vereador(a)
                      </h2>

                      <p>{{dadosVereador.name}}, is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
<p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
<p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with deskto</p>
<p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including ver</p>
<p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
<p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker includ</p>
<p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions o</p>
                          <a class="button-secondary" href="#" title="link do perfil do vereador(a)" style="color: #04185b">Visite o perfil completo do Vereador(a)</a>
                                                  </div>

                  <div class="coluna-direita">
                      <h4 class="desktop-headeline-4">Compartilhe</h4>
                      <div class="compartilhe-redes mt-8">
                          <a href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.saopaulo.sp.leg.br%2Fvereadores%2Fpresidencia-da-camara%2F" target="_blank">
                              <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-linkedin.svg" alt="Compartilhar Linkedin">
                          </a>
                          <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.saopaulo.sp.leg.br%2Fvereadores%2Fpresidencia-da-camara%2F" target="_blank">
                              <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-facebook.svg" alt="Compartilhar Facebook">
                          </a>
                          <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.saopaulo.sp.leg.br%2Fvereadores%2Fpresidencia-da-camara%2F" target="_blank">
                              <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-twitter.svg" alt="Compartilhar Twitter">
                          </a>
                          <a href="mailto:?subject=Presid%C3%AAncia+da+C%C3%A2mara&amp;body=https%3A%2F%2Fwww.saopaulo.sp.leg.br%2Fvereadores%2Fpresidencia-da-camara%2F" target="_blank">
                              <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/assets/images/post-email.svg" alt="Compartilhar E-mail">
                          </a>
                      </div>

                      <div class="infos-gabinete mt-32">
                          <h3 class="desktop-headeline-4">Infos do Gabinete:</h3>
                                                              <ul>
                              <li class="mt-16">
                                  <strong>Chefe de Gabinete da Presidência:</strong>
                                  Fulano de tal                                        </li>
                              <li class="mt-16">
                                  <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/dist/images/Phone.svg" alt="telefone">
                                  <strong>Telefone:</strong> (65) 9999-9999 <br> (65) 9999-9999                                        </li>
                              <li class="mt-16">
                                  <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/dist/images/Email.svg" alt="e-mail">
                                  
                              <li class="mt-16">
                                  <img src="https://www.saopaulo.sp.leg.br/wp-content/themes/portalcmsp-novo/dist/images/icon-pin.svg" alt="endereco">
                                  <div><strong>Endereço:</strong> Palácio Anchieta Viaduto Jacareí, 100, 8°Andar, Sala 808 - São Paulo - SP - CEP 01319-900                                            </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>









<div class="w100 mt-32" style="background-color: #223988;">
  <div class="container" style="padding: 15px;">
  <div class="row w100 atendimento" >
    <div class="col-lg-2 col-md-12 col-sm-12">
<h3 class="text-white">Atendimento:</h3>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 text-center">
<h6 class="text-white">
(65) 3301-1150
</h6>
<h6 class="text-white">Das 13:00hs ás 18:00hs
</h6>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 text-center">
      <h6 class="text-white">Câmara Municipal de Chapada dos Guimarães - MT</h6>
      <h6 class="text-white">Av. Fernando Corrêa, Centro, n°. 763, CEP: 78.195-000, </h6>
      <a class="text-white" href="mailto:administrativo&#64;camarachapadadosguimaraes.mt.gov.br">
        <span>administrativo&#64;camarachapadadosguimaraes.mt.gov.br</span>
      </a>
    </div>

  </div>
  </div>

</div> 



