import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyComponent } from './agency/agency.component';
import { AgencyVideoComponent } from './agency/agency-video/agency-video.component';
import { AgencyCounterComponent } from './agency/agency-counter/agency-counter.component';
import { AgencyServicesComponent } from './agency/agency-services/agency-services.component';
import { VereadoresPreviewComponent } from './agency/vereadores-preview/vereadores-preview.component';
import { NewsComponent } from './agency/news/news.component';
import { GalleryComponent } from './agency/gallery/gallery.component';

const routes: Routes = [
  {
    path: '',
    component: AgencyComponent,
    data: {
      title: "Agency | Unice Landing Page",
      content: "Agency Angular 8 landing page with Angular Universal | SSR | SEO friendly",
      breadcrumb: 'Home'
    }
  },
  {
    path: 'vereadores/:nome',
    component: VereadoresPreviewComponent,
    data: {
      title: "Vereadores",
      content: "Agency Angular 8 landing page with Angular Universal | SSR | SEO friendly",
      breadcrumb: 'Vereadores' 
    }
  },
  {
    path: 'noticias/:nome',
    component: NewsComponent,
    data: {
      title: "Notícias",
      content: "Agency Angular 8 landing page with Angular Universal | SSR | SEO friendly",
      breadcrumb: 'Vereadores' 
    }
  },
  {
    path: 'galeria-imagens',
    component: GalleryComponent,
    data: {
      title: "Banco De Imagens",
      content: "Agency Angular 8 landing page with Angular Universal | SSR | SEO friendly",
      breadcrumb: 'Vereadores' 
    }
  },
 
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
