import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-vereadores',
  templateUrl: './vereadores.component.html',
  styleUrl: './vereadores.component.scss'
})
export class VereadoresComponent implements OnInit{
  fadeInRight: any;
  abaSelected: any = null
  dadosVereador:any
  displayDetailVereador: boolean = false
  vereadores = [
    { 
     img:"assets/images/vereadores/ronadil.jpg",
     name:"Ronadil Amorin",
     designation:"PP"
    },
    { 
      img:"assets/images/vereadores/ademir.jpg",
      name:"Ademir do sindicato",
      designation:"PSDB"
     },
     { 
      img:"assets/images/vereadores/kinho.jpg",
      name:"Kinho",
      designation:"União Brasil"
     },
     { 
      img:"assets/images/vereadores/angela.jpg",
      name:"Angela Brito",
      designation:"PL"
     },
     { 
      img:"assets/images/vereadores/cida.jpg",
      name:"Cida Lessa",
      designation:"PSD"
     },
     { 
      img:"assets/images/vereadores/professor.jpg",
      name:"Professor Paulinho",
      designation:"PSD"
     },
     { 
      img:"assets/images/vereadores/carlinhos.jpg",
      name:"Carlinhos da distribuidora",
      designation:"PP"
     },
     { 
      img:"assets/images/vereadores/gilberto.jpg",
      name:"Gilberto Mello",
      designation:"PL"
     },
     { 
      img:"assets/images/vereadores/joair.jpg",
      name:"Joair Siqueira",
      designation:"União Brasil"
     },
     { 
      img:"assets/images/vereadores/ze.jpg",
      name:"Zé Otávio",
      designation:"PL"
     },
     { 
      img:"assets/images/vereadores/rafael.jpg",
      name:"Rafael Nilo",
      designation:"PSD"
     },
  ]

  documentos = [
    {
      data: '30/09/2024',
      categoria: 'Geral',
      titulo: 'Moção de Pesar nº 056-2024',
      descricao: 'Apresentada MOÇÃO DE PESAR, externando os mais profundos sentimentos aos familiares...',
    },
    {
      data: '06/09/2024',
      categoria: 'Geral',
      titulo: 'Indicação nº 189-2023',
      descricao: 'Indica a viabilização para colocação de iluminação...',
    }
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('nome') !== 'categorias') {
      let vereador = this.vereadores.find(x => x.name == this.route.snapshot.paramMap.get('nome'))
      this.abaSelected = this.route.snapshot.paramMap.get('nome')
      this.selectVereador(vereador)
    }
  }

  selectAba(event){
    this.abaSelected = event
  }

  selectVereador(event){
    this.displayDetailVereador = true
    this.dadosVereador = event
  }

  
  
  visualizar(doc: any) {
    console.log('Visualizando', doc);
  }
  
  baixar(doc: any) {
    console.log('Baixando', doc);
  }
}
